<template>
    <div class="add-monitor-main">
        <div class="main-banner">
            <div class="head-desc">
                <p class="title">{{$t("bloodPressure.xysjRecord")}}</p>
                <p class="txt">{{$t("bloodPressure.desctitle")}}</p>
            </div>
        </div>
        <div class="main-from">
            <!-- <div class="from-item">
                <span class="lable">{{$t("bloodPressure.Surveyor")}}：</span>
                <div class="time input" @click="openSurveyor">{{fromData.patientName || $t("bloodPressure.pleasepatientName")}}</div>
                <van-icon name="arrow icon"  @click="openSurveyor" />
            </div> -->
            <div class="from-item">
                <span class="lable">{{$t("bloodPressure.measuretime")}}：</span>
                <div class="time input" @click="openpicker">{{fromData.ct}}</div>
                <van-icon name="arrow icon"  @click="openpicker" />
            </div>
            <div class="from-item">
                <span class="lable">{{$t("bloodPressure.deviceType")}}：</span>
                <van-radio-group v-model="fromData.device" direction="horizontal" class="input radioinput">
                    <van-radio :name='$t("bloodPressure.electronxyj")'>{{$t("bloodPressure.electronxyj")}}</van-radio>
                    <van-radio :name='$t("bloodPressure.mercuryxyj")'>{{$t("bloodPressure.mercuryxyj")}}</van-radio>
                </van-radio-group>
            </div>
            <div class="from-item" >
                <span class="lable requiredlable">{{$t("bloodPressure.systolicPressureHigh")}}：</span>
                <van-field class="input" v-model="fromData.sys" @blur="!/^[0-9]*[1-9][0-9]*$/.test(fromData.sys)?fromData.sys='':''" type="number" maxlength="3" placeholder="请输入" />
                <span class="icon-txt">mmHg</span>
            </div>
            <div class="from-item">
                <span class="lable requiredlable">{{$t("bloodPressure.systolicPressureLow")}}：</span>
                <van-field class="input" v-model="fromData.dia" type="number" @blur="!/^[0-9]*[1-9][0-9]*$/.test(fromData.dia)?fromData.dia='':''" maxlength="3" placeholder="请输入" />
                <span class="icon-txt">mmHg</span>
            </div>
            <div class="from-item">
                <span class="lable">{{$t("bloodPressure.heart")}}：</span>
                <van-field class="input" v-model="fromData.heartRate" @blur="!/^[0-9]*[1-9][0-9]*$/.test(fromData.heartRate)?fromData.heartRate='':''" type="number" maxlength="3" placeholder="请输入" />
                <span class="icon-txt">{{$t("bloodPressure.heartCompany")}}</span>
            </div>
            <div class="from-item">
                <span class="lable">{{$t("bloodPressure.remaskermsg")}}：</span>
                <van-field class="input" v-model="fromData.summary"   maxlength="50" :placeholder='$t("bloodPressure.placeholderremakerMsg")' />
            </div>
        </div>
        <div class="btn-bottom" @click="save">
            {{$t("bloodPressure.preserve")}}
        </div>
        <van-popup v-model:show="pickerDateShow" position="bottom" >
            <van-datetime-picker
                v-model="currentDate"
                type="datetime"
                @confirm="dateConfirm"
                @cancel="pickerDateShow=false"
                :title='$t("bloodPressure.selectMonth")'
                :min-date="minDate"
                :max-date="maxDate"
            />
        </van-popup>
        <loading v-if="isLoading" msg="加载中..."></loading>
    </div>
</template>

<script>
import { getLocalMinutesTime } from "@/utils/date";
import Loading from "@/components/ui/Loading";
import VConsole from 'vconsole/dist/vconsole.min.js'
import { pressurAdd } from "@/api/index";
// Vue.prototype.VConsole = new VConsole();
import { patientQuery } from "@/api/patient";

export default {
    components:{Loading},
    data(){
        return{
            minDate: new Date(new Date().getFullYear()-1, 0, 1),
            maxDate: new Date(),
            currentDate: new Date(),
            isLoading:false,
            isApp:(window.navigator.userAgent.indexOf('cxo2sskkbb') !== -1),
            fromData:{
                ct:"",
                sys:"",
                dia:"",
                device:this.$t("bloodPressure.electronxyj"),
                summary:"",
                patientId:"",
                patientName:"",
                patientId:this.$store.getters.selectPatientId,
                heartRate:""
            },
            pickerDateShow:false
        }
    },
    computed:{
		getters(){
			return this.$store.getters
		}
	},

    watch:{
        'getters':{
			handler(news,old){
				if(this.$store.getters.patientSelectIndex != null){
                    let res = this.$store.getters.patientData[this.$store.getters.patientSelectIndex];
                    this.fromData.patientId = res.id;
                    this.fromData.patientName = res.name;

					// this.$store.commit("user/SET_PATIENTINDEX",null);
				}
			},
			deep:true
		},
    },
    destroyed(){
        this.$store.commit("user/SET_PATIENTINDEX",null);
    },
    created(){
        document.title = this.$t("bloodPressure.addRecord");
        this.fromData.ct = getLocalMinutesTime(new Date().getTime());
        // new VConsole()
    },
    methods:{
        dateConfirm(e){
            this.fromData.ct =  getLocalMinutesTime(e);
            this.pickerDateShow = false;
        },
        openpicker(){
            this.currentDate = new Date(this.fromData.ct.split("-").join('/'));
            // alert("this.currentDate"+this.currentDate+'---'+new Date(this.fromData.ct.split("-").join('/')).getTime());
            this.pickerDateShow = true;
        },
        // openSurveyor(){
        //     if(this.fromData.patientId){
        //         let index = this.$store.getters.patientData.findIndex(item => item.id  == this.fromData.patientId);
        //         console.log(index);
        //         this.$store.commit("user/SET_PATIENTINDEX",index);
        //     }
        //     this.$store.commit("user/SET_SENDPATIENT",true);
        // },
        // get
        async save(){
            // if(!this.fromData.patientId){
            //     this.$toast("请选择测量人");
            //     return;
            // }
            if(!this.fromData.sys){
                this.$toast("请输入收缩压");
                return
            }
            if(!this.fromData.dia){
                this.$toast("请输入舒张压");
                return
            }
            // if(!this.fromData.heartRate){
            //     this.$toast("请输入心率");
            //     return
            // }
            this.isLoading = true;
            pressurAdd(this.fromData).then((res)=>{
                if(res.data.errorCode == 0){
                    this.$router.go(-1);
                }
                this.isLoading = false;
            },(err)=>{
                this.isLoading = false;
            });
        }
    }
}
</script>
<style lang="less">
.van-picker__title{
    font-size: 0.5rem;
    line-height: 100%;
}
.radioinput {
    .van-radio {
        align-items: center;
        display: flex;
        .van-radio__icon {
            height: auto;

        }
        .van-icon-success{
            width: 0.72rem;
            height: 0.72rem;
            display: flex;
            align-items: center;
            justify-content: center;
            &::before{
                height: 0.36rem;
                width: 0.36rem;
                content: " ";
                border-radius: 50%;
                background-color: #FFFFFF;
            }
        }
    }
}
// .add-monitor-main .van-icon-success::before{
//     position: absolute;
//     top: 10;
// }
</style>
<style lang="less" scoped>
.add-monitor-main{
    height: 100%;
    background: #f7f7f7;
    .main-banner{
        height: 4.6rem;
        background: url("~@/assets/img/monitor-add-banner.png") no-repeat;
        background-size: cover;
        position: relative;
        box-sizing: border-box;
        padding: 1.6rem 0.6rem;
        &::after{
            content: " ";
            position: absolute;
            width: 3.64rem;
            right: 1.32rem;
            top: 0.46rem;
            height: 4.1rem;
            z-index: 0;
            background: url("~@/assets/img/monitor-add-container.png") no-repeat;
            background-size: cover;
        }
        .head-desc{
            height: 2.8rem;
            .title{
                font-size: 0.8rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #181818;
                line-height: 1.12rem;
                margin-bottom: 0.2rem;
            }
            .txt{
                font-size: 0.56rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #696969;
                line-height: 0.8rem;
            }
        }

    }
    .main-from{
        background-color: #fff;
        padding: 0 0.6rem;
        .from-item{
            height: 2.2rem;
            border-bottom: 0.02rem solid #EDEDED;
            display: flex;
            align-items: center;
            &:last-child{
                border: 0;
            }
            .lable{
                flex: 0 0 auto;
                font-size: 0.64rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #181818;
                position: relative;
            }
            .requiredlable::after{
                color: #ee0a24;
                font-size: 0.4rem;
                position: absolute;
                top: 50%;
                transform: translate(0,-50%);
                left: -0.3rem;
                content: '*';
            }
            .input{
                flex: 1;
                font-size: 0.64rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #181818;
            }
            .radioinput{
                display: flex;
                height: 100%;
                .van-radio:last-child {
                    margin-left: 0.6rem;
                }
            }
            .icon{
                flex: 0 0 auto;
                font-size: 0.7rem;
            }
            .icon-txt{
                font-size: 0.64rem;
                flex: 0 0 auto;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #696969;
            }
        }
    }
    .btn-bottom{
        width: 12.8rem;
        margin: 0.6rem auto 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 1.76rem;
        background: #298AFB;
        border-radius: 0.88rem;
        font-size: 0.72rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
    }
}
</style>
